.overlay {
    position: absolute;
    top: 2rem;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.overlayCard{
    position: absolute;
    top: 10rem;
    bottom: 0;
    left: 55rem;
    right: 0;
    display: flex;
    align-items: center;
}